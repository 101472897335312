/******** Navbar Section ********/
.vertical-timeline-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 20px;
  margin-left: 20px;
  z-index: 100;
}

.vertical-timeline-logo {
  height: 40px;
  cursor: pointer;
}

/******** Page Background ********/
.vertical-timeline-page {
  padding-top: 60px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: transparent;
}

.vertical-timeline-page particles-bg,
.vertical-timeline-page particles-bg-canvas-self {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

/******** Container Section ********/
.vertical-timelineContainer {
  padding-top: 60px 20px;
  /* position: relative; */
  max-width: 1200px;
  margin: 0 auto;
  background: transparent;
  z-index: 1;
}

@media (max-width: 768px) {
  .vertical-timelineContainer {
    padding: 40px 10px;
  }
}

@media (min-width: 769px) {
  .vertical-timelineContainer {
    padding: 60px 30px;
  }
}

/******** Timeline Section ********/
.vertical-timeline {
  margin-left: 200px;
}

/******** Elements Section ********/
.vertical-timeline-element {
  width: 90% !important;
  max-width: 950px !important;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.vertical-timeline-element:hover {
  transform: translateY(-15px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.vertical-timeline-element.show {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .vertical-timeline-element {
    max-width: 95%;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .vertical-timeline-element {
    max-width: 80%;
  }
}

@media (min-width: 1201px) {
  .vertical-timeline-element {
    max-width: 70%;
  }
}

/******** Content Section ********/
.vertical-timeline-description,
.vertical-timeline-event,
.vertical-timeline-event p {
  font-size: 18px;
  line-height: 1.8;
  color: black;
}

.vertical-timeline-event h4 {
  margin-bottom: 10px;
}

.vertical-timeline-event u {
  text-decoration: underline;
  font-size: 18px;
  color: black;
}

.vertical-timeline-event strong {
  font-weight: bold;
  font-size: 18px;
  color: black;
}

.vertical-timeline-event em {
  font-style: italic;
  font-size: 18px;
  color: black;
}

.competition-name {
  font-weight: bold !important;
  color: #f0ad4e !important;
}

/******** List Section ********/
.vertical-timeline-list {
  list-style-type: none;
  padding-left: 0;
}

.vertical-timeline-list li {
  margin-bottom: 5px;
}

/******** Year Section ********/
.year-marker {
  font-size: 50px;
  text-align: left;
  color: #666;
  margin-top: 10px !important;
  margin-left: 5% !important;
}

.sticky-year {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  color: #ffffff;
  border-radius: 5px;
  font-weight: bolder;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(136, 134, 134, 0.6);
  margin-bottom: 24px;
}

@media (min-width: 1200px) {
  .year-marker {
    margin-left: -10% !important;
  }

  .sticky-year {
    margin-left: -10% !important;
  }
}

@media (max-width: 768px) {
  .year-marker {
    font-size: 30px;
    margin-left: 10px;
  }

  .sticky-year {
    margin-left: 10px;
  }
}

/******** Month Section ********/
.vertical-timeline-month-title {
  font-family: "Gotham", sans-serif;
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 25px;
  color: #272276;
  text-shadow: 2px 2px 4px rgba(20, 34, 57, 0.3);
}

.vertical-timeline-month {
  font-size: 20px;
  font-weight: bolder;
  color: rgb(132, 129, 129);
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .vertical-timeline-month {
    font-size: 14px;
  }
}
